import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Badge from "react-bootstrap/Badge";

class Experience extends Component {
  render() {
    if (this.props.resumeExperience && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.experience;
      var work = this.props.resumeExperience.map(function (work, i) {
        const technologies = work.technologies;
        const mainTechnologies = work.mainTech;

        var mainTech = mainTechnologies.map((technology, i) => {
          return (
            <Badge pill className="main-badge mr-2 mb-2" key={i}>
              {technology}
            </Badge>
          );
        });
        var tech = technologies.map((technology, i) => {
          return (
            <Badge pill 
            className="experience-badge mr-2 mb-2" 
            key={i}>
              {technology}
            </Badge>
          );
        });
        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date={work.years}
            iconStyle={{
              background: "#d6c6e7 ",
              color: "#fff",
              textAlign: "center",
            }}
            icon={<i className="fab fa-angular experience-icon"></i>}
            key={i}
          >
            {/* <div style={{ textAlign: "left", marginBottom: "4px" }}>
              {mainTech}
            </div> */}

            <h3
              className="vertical-timeline-element-title"
              style={{ textAlign: "left" }}
            >
              {work.title}
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ textAlign: "left" }}
            >
              {work.company}
            </h4>
            <p
              style={{
                textAlign: "left",
                fontSize: "14px", // Smaller font for description
                marginBottom: "15px",
              }}
            >
              {work.description}
            </p>
            <div style={{ textAlign: "left", marginTop: "15px" }}>{tech}</div>
          </VerticalTimelineElement>
        );
      });
    }

    return (
      <section id="resume" className="pb-5">
        <div className="col-md-12 mx-auto">
          <div className="col-md-12">
            <h1 className="section-title" style={{ color: "black" }}>
              <span className="text-black" style={{ textAlign: "center" }}>
                {sectionName}
              </span>
            </h1>
          </div>
        </div>
        <div className="col-md-8 mx-auto">
          <VerticalTimeline>
            {work}
            <VerticalTimelineElement
              iconStyle={{
                background: "#d6c6e7 ",
                color: "#fff",
                textAlign: "center",
              }}
              icon={
                <i className="fas fa-hourglass-start mx-auto experience-icon"></i>
              }
            />
          </VerticalTimeline>
        </div>
      </section>
    );
  }
}

export default Experience;
// import React from 'react';
// import data from "../../data/index.json"; // Make sure this is the correct path and import
// import Card from './card';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Resume = () => {
//     return (
//         <section className='resume container section' id='resume'>
// <h1 className="r--heading">Experience</h1>
//             <div className="resume__container grid" data-aos="fade-in">
//                 <div className="timeline" >
//                     {data.Experience.map((val, id) => { 
//                         if (val.category === "Education") {
//                             return (
//                                 <Card
//                                     key={id}
//                                     icon={val.icon}
//                                     title={val.title}
//                                     year={val.year}
//                                     place={val.place}
//                                     location={val.location}
//                                     desc={val.description}
//                                 />
//                             )
//                         }
//                         return null;
//                     })}
//                 </div>
//                 <div className="timeline">
//                     {data.Experience.map((val, id) => { 
//                         if (val.category === "Experience") {
//                             return (
//                                 <Card
//                                     key={id}
//                                     icon={val.icon}
//                                     title={val.title}
//                                     year={val.year}
//                                     place={val.place}
//                                     location={val.location}
//                                     desc={val.description}
//                                 />
//                             )
//                         }
//                         return null;
//                     })}
//                 </div>
//             </div>
//         </section>
//     )
// }

// export default Resume;




// import React, { useEffect } from 'react';
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Ensure the CSS file is imported
// import data from "../../data/index.json"; 
// import Card from './card';

// const Resume = () => {
//     useEffect(() => {
//         // Setting a longer duration for a slower transition
//         AOS.init({ 
//             duration: 2000, // 3000ms = 3 seconds
//             easing: 'ease-in-out', // Adjust as needed
//             offset: 100 // Optional: when to start the animation (100px from the viewport)
//         });
//     }, []);

//     return (
//         <section className='resume container section' id='resume'>
//             <h1 className="r--heading">Experience</h1>
//             <div className="resume__container grid" data-aos="fade-in">
//                 <div className="timeline">
//                     {data.Experience.map((val, id) => {
//                         if (val.category === "Education") {
//                             return (
//                                 <Card
//                                     key={id}
//                                     icon={val.icon}
//                                     title={val.title}
//                                     year={val.year}
//                                     place={val.place}
//                                     location={val.location}
//                                     desc={val.description}
//                                 />
//                             );
//                         }
//                         return null;
//                     })}
//                 </div>
//                 <div className="timeline">
//                     {data.Experience.map((val, id) => {
//                         if (val.category === "Experience") {
//                             return (
//                                 <Card
//                                     key={id}
//                                     icon={val.icon}
//                                     title={val.title}
//                                     year={val.year}
//                                     place={val.place}
//                                     location={val.location}
//                                     desc={val.description}
//                                 />
//                             );
//                         }
//                         return null;
//                     })}
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default Resume;
