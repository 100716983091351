import React, { Component } from "react";

class Skills extends Component {
  render() {
    let sectionName = "Skills"; // Default in case the prop is not passed
    let skills = []; // Default to empty array

    if (this.props.sharedSkills && this.props.resumeBasicInfo) {
      sectionName = this.props.resumeBasicInfo.section_name.skills;
      skills = this.props.sharedSkills.icons.map((skill, i) => (
        <li className="skills-list-item mx-3" key={i}>
          <div className="text-center skills-tile">
            <i className={skill.class} style={{ fontSize: "220%" }}></i>
            <p className="skills-name" style={{ fontSize: "30%", marginTop: "4px" }}>
              {skill.name}
            </p>
          </div>
        </li>
      ));
    }

    return (
      <section id="skills" className="skills-section">
        <div className="skills-container">
          <div className="skills-title-container">
            <h1 className="section-title">
              <span>{sectionName}</span>
            </h1>
          </div>
          <div className="skills-list-container">
            <ul className="skills-list">{skills}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;





// import data from "../../data/index.json";

// export default function MySkills() {
//     return (
//         <section className="skills--section" id="MySkills">
//             <div className="portfolio--container">
//                 {/* <p className="section--title">
//                     My Skills
//                 </p> */}
//                 <h2 className="skills--section--heading">
//                     Skills
//                 </h2>
//             </div>
//             <div className="skills--section--container">
//                 {data?.skills?.map((item, index) => (
//                     <div key={index} className="skills--section--card">
//                         {/* <div className="skills--section--img">
//                             <img src={item.src} alt="Product Chain"/> </div> */}
//                     <div className="skills--section--card--content">
//                         <h3 className="skills--section--title">{item.title}</h3>
//                         <p className="skills--section--description">{item.description}</p>
//                     </div>
//                     </div>
//                 ))}

//             </div>
//         </section>
//     )
// }





// import React, { useState } from 'react';
// import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
// import data from "../../data/index.json";
// // import './HeroSection.css'; // Ensure you have an external CSS file for your styles

// export default function MySkills() {
//   const [activeSlide, setActiveSlide] = useState(0);

//   return (
//     <section className="skills--section" id="MySkills">
//       <div className="s--container">
//         <h1 className="s--heading">Skills</h1>
//         <Swiper
//           effect={'coverflow'}
//           grabCursor={true}
//           centeredSlides={true}
//           loop={true}
//           slidesPerView={3} // Use 'auto' to center the first slide
//           spaceBetween={30}
//           coverflowEffect={{
//             rotate: 50,
//             stretch: 0,
//             depth: 100,
//             modifier: 1,
//           }}
//           pagination={{ clickable: true }}
//           navigation={true}
//           modules={[EffectCoverflow, Pagination, Navigation]}
//           className="swiper_container"
//           onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
//         >
//           {data.skills.map((skill, index) => (
//             <SwiperSlide key={index}>
//               <div className={`skill-card ${activeSlide === index ? 'skill-card-active' : ''}`}>
//                 <h3 className="skill-title">{skill.title}</h3>
//                 <ul className={`skill-description ${skill.title === 'Machine-Learning Libraries' ? 'machine-learning' : ''}`}>
//                   {skill.description.map((desc, idx) => (
//                     <li key={idx}>{desc}</li>
//                   ))}
//                 </ul>
//               </div>
//             </SwiperSlide>
//           ))} 
//         </Swiper>
//       </div>
//     </section>
//   );
// }

