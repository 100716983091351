import HeroSection from "../HeroSection";
import MyPortfolio from "../MyPortfolio";
import Skills from "../MySkills";
import Footer from "../Footer";
import Resume from "../Resume";
import SkillsExperience from "../skills/SkillsExperience";
// import Preloader from "../Pre/Preloader";
import info from '../info.json';
import Experience from "../Resume";

import 'bootstrap/dist/css/bootstrap.min.css';

export default function Home() {
  return (
    <>
      {/* <Preloader></Preloader> */}
      <HeroSection />
      <Experience
        resumeExperience={info.experience} // Assuming `info` contains `experience` data
        resumeBasicInfo={info.basic_info}  // Assuming `info` contains `basic_info`
      />
      {/* <MySkills></MySkills> */}
      <Skills
        sharedSkills={info.skills}
        resumeBasicInfo={info.basic_info}
      />
      <MyPortfolio />
      <Footer />
    </>
  );
}
